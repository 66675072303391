
export default class CustomSelect {
    constructor(select) {
        this.select = select;
    } 
    
    init_select() {
        this.selects = document.querySelectorAll(this.select);

        for(let i = 0; i < this.selects.length; i++) {
            this.select = this.selects[i];

            this.select_name = this.select.getAttribute('name');
            this.select.style.display = 'none';

            this.select_parent = this.select.parentElement;

            this.new_select = document.createElement('div');
            this.new_select.classList.add('select');

            this.select_parent.append(this.new_select);
            
            this.new_select.insertAdjacentHTML('beforeend',
            `<div class="select-item">
            <input class="hidden-input" type="hidden" name=${this.select_name}></input>
            <div class="select-title"><span></span></div>
            <div class="select-options"></div> 
            </div></div>`);

            this.init_options(this.select, this.new_select);
       
        }

        this.select_item = document.querySelectorAll('.select-item');

        this.select_item.forEach(item => {
            item.addEventListener('click', (e) => {
                this.toggleSelect(e);
            })
        })

    }

    init_options(select, new_select) {

        this.options = select.querySelectorAll('option');
        this.new_select_options = new_select.querySelector('.select-options');

        for(let i = 0; i < this.options.length; i++) {
            this.option = this.options[i];

            this.option_value = this.option.value;
            this.option_content = this.option.textContent;

            this.new_select_options.insertAdjacentHTML('beforeend',
            `<label class="select-option" for=${this.option_content}>
            <input type="radio" id=${this.option_content} value=${this.option_value}></input>${this.option_content}</label>
            `)
        }

     
        this.new_select_options.addEventListener('click', (e) => {
            const target = e.target;

            if(target.classList.contains('select-option')) {
                const target_parent = target.parentElement;
                this.selected_option = target.innerText;
                this.selected_option_value = target.querySelector('input').value;
                this.select_title = target_parent.previousElementSibling;

                this.select_title_span = this.select_title.querySelector('span');

                this.select_title_span.textContent = this.selected_option;
                this.hidden_input = this.select_title.previousElementSibling;
                this.hidden_input.setAttribute('value', this.selected_option_value);
                this.toggleSelect(e);
            }
        
        })
        
    }

    toggleSelect(e) {
        let target = e.target;
        let isOpen = target.closest('.select').classList.contains('active');
         this.closeSelects();
        if(!isOpen)
        target.closest('.select').classList.toggle('active');
       
    }


    closeSelects() {
        this.new_select = document.querySelectorAll('.select');
        this.new_select.forEach(item => {
            item.classList.remove('active');
        })
    }

}

