export default class Slider {
    constructor(obj) {
        this.slider = obj.slider;
        this.slidesSelector = obj.slides;
        this.track = obj.track;
        this.wrapper = obj.wrapper;
        this.slideFooter = obj.slideFooter;
        this.btnNext = obj.btnNext;
        this.btnPrev = obj.btnPrev;
        this.autoplay = obj.autoplay;
        this.speed = obj.speed;
        this.index = 0;
        this.currentSlide = 0;
        this.currentDot = 0;
        this.movePosition = 0;
        this.firstInit = true;
        this.indicators = [];
        this.slidesToScroll = 1;
        this.slidesToShow = 1;
        this.position = 0;
        this.treshold = 140;
        this.allowedTime = 200;
        this._startX = 0;
        this._startY = 0;
        this._startTime = 0;
    }

    init() {

        this.slider = document.querySelector(this.slider);

        if (!this.slider) {
            return;
        }

        this.slides = document.querySelectorAll(this.slidesSelector);
        this.slidesLength = this.slides.length;
        this.track = document.querySelector(this.track);
        this.wrapper = document.querySelector(this.wrapper);
        this.btnNext = document.querySelector(this.btnNext);
        this.btnPrev = document.querySelector(this.btnPrev);

        this.calc();

        if (this.autoplay) {
           this.startAutoplay();
        }

        
        this.btnNext.addEventListener('click', () => {
            this.next();
        })

        this.btnPrev.addEventListener('click', () => {
            this.prev();
        })

        this.slider.addEventListener('mouseover', () => {
            this.stopAutoplay();
        })

        this.slider.addEventListener('mouseout', () => {
            this.startAutoplay();
        })

        const dots = document.createElement('ul');
        dots.classList.add('dots', 'isMobile');
        this.wrapper.appendChild(dots);

        for (let i = 0; i < this.slidesLength; i++) {
            const dot = document.createElement('li');
            dot.classList.add('dot');
            const slideTo = dot.setAttribute('data-slide-to', i + 1);

            if (i === 0) {
                dot.classList.add('dot-active');
            }

            dots.appendChild(dot);
            this.indicators.push(dot);
        }


        this.slider.addEventListener('touchstart', (e) => {
            const touchObj = e.changedTouches[0];
            this._startX = touchObj.pageX;
            this._startY = touchObj.pageY;
            this._startTime = new Date().getTime();
        }, {
            passive: true
        });

        this.slider.addEventListener('touchend', (e) => {
            const touchObj = e.changedTouches[0];
            const dist = touchObj.pageX - this._startX;
            const elapsedTime = new Date().getTime() - this._startTime;

            if (Math.abs(touchObj.pageY - this._startY) < 100 &&
                elapsedTime <= this.allowedTime &&
                Math.abs(dist) >= this.treshold) {
                const swiperRightBol = dist > 0;

                if (swiperRightBol) {
                    this.prev();
                } else {
                    this.next();
                }

            }
        }, {
            passive: true
        });

        window.addEventListener('resize', () => {
            this.track.classList.remove('animated');
            this.calc();
            this.position = -(this.currentSlide * this.movePosition);
            this.setPosition();
        }, {
            passive: true
        });

    }

    startAutoplay() {
        this.timer = setInterval(() => {
            this.next();
        }, this.speed)
    }

    stopAutoplay() {
        clearInterval(this.timer);
    }

    calc() {
        this.track.style.width = 100 * this.slides.length + '%';

        this.movePosition = window.getComputedStyle(this.wrapper).width;
        this.movePosition = this.deleteNoDigits(this.movePosition);

        this.leftX = this.slides[1].getBoundingClientRect().left;
        this.ww = window.screen.width;

        this.setPosition();
        this.track.offsetHeight;
        this.track.classList.add('animated');

    }

    setPosition() {
        this.track.style.transform = `translateX(${this.position}px)`;
    }

    deleteNoDigits(str) {
        return +str.replace(/[A-Za-z]/g, '');
    }

    next() {
        if (this.firstInit) {
            this.calc();
            this.firstInit = false;
        }
        if (this.slides[0].getBoundingClientRect().right < 0) {

            let temp = this.track.removeChild(this.slides[0]);
            this.track.appendChild(temp);

            this.slides = document.querySelectorAll(this.slidesSelector);

            this.position += this.movePosition;
            this.currentSlide--;
            this.track.classList.remove('animated');
            this.setPosition();
            this.track.offsetHeight;
            this.track.classList.add('animated');
        }

        this.position -= this.movePosition;
        this.currentSlide++;

        this.setPosition();
        this.currentDot++;
        if (this.currentDot >= this.indicators.length) {
            this.currentDot = 0;
        }
        this.updateDot();
    }

    prev() {
        if (this.firstInit) {
            this.calc();
            this.firstInit = false;
        }
        if (this.slides[this.slides.length - 1].getBoundingClientRect().left > this.ww) {

            let temp = this.track.removeChild(this.slides[this.slides.length - 1]);
            this.track.insertBefore(temp, this.slides[0]);

            this.slides = document.querySelectorAll(this.slidesSelector);

            this.position -= this.movePosition;
            this.currentSlide++;
            this.track.classList.remove('animated');
            this.setPosition();
            this.track.offsetHeight;
            this.track.classList.add('animated');

        }
        this.position += this.movePosition;
        this.currentSlide--;
        this.setPosition();
        this.currentDot--;
        if (this.currentDot < 0) {
            this.currentDot = this.indicators.length - 1;
        }
        this.updateDot();
    }

    updateDot() {
        this.indicators.forEach(item => {
            item.classList.remove('dot-active');
        })
        this.indicators[this.currentDot].classList.add('dot-active');
    }

}