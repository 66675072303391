import CustomSelect from './modules/select';
import Slider from './modules/slider';

window.addEventListener('DOMContentLoaded', () => {
    'use strict';
    new CustomSelect('select').init_select();

    new Slider({
        slider: '.slider',
        slides: '.slide',
        track: '.slider-track',
        wrapper: '.slider-wrapper',
        btnNext: '.btn-next',
        btnPrev: '.btn-prev',
        autoplay: true,
        speed: 6000
    }).init();

})